body,
html {
  background-color: #000 !important;
  /* background-image: url("../images/background1.png") !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important; */
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.header-row {
  width: 100%;
  position: relative;
  z-index: 6;
}

.container-item {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-row .header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 50px;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    #424242 50.25%,
    #292929 70.13%,
    #424242 84.96%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  height: 100px;
  justify-content: space-between;
  padding-right: 35px;
}
.authed-user {
  display: flex;
  align-items: center;
}
.authed-user .wellcome {
  color: #e0c78c;
  font-family: "helv55";
  font-size: 14px;
  margin-right: 40px;
}
.authed-user .abbtr {
  width: 70px;
  height: 70px;
  background: #d0b478;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4d4d4d;
  border-radius: 16px;

  position: relative;
}
.authed-user .abbtr .logout-popover {
  position: absolute;
  width: 250px;
  padding-top: 240px;
  border-radius: 5px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.authed-user .abbtr .logout-popover[data-attr="true"] {
  padding-top: 135px;
}
.authed-user .abbtr .logout-popover .logout-btn {
  background: #fff;
  padding: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.authed-user .abbtr .logout-popover .logout-btn:hover {
  opacity: 0.9;
  transition: all 0.3s;
}
.authed-user .abbtr .logout-popover .profile-btn {
  background: #fff;
  padding: 18px;
  cursor: pointer;
  transition: all 0.3s;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #ddd;
}
.authed-user .abbtr .logout-popover .profile-btn:first-child {
  border-top-right-radius: 8px;
}
.authed-user .abbtr .logout-popover .profile-btn:first-child {
  border-top-left-radius: 8px;
}
.authed-user .abbtr .logout-popover .profile-btn:hover {
  opacity: 0.9;
  transition: all 0.3s;
}
.authed-user .abbtr:hover .logout-popover {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
.header-row .header .left {
  display: flex;
  align-items: center;
}

.header-row .header .logo-item {
  margin-left: 35px;
  margin-top: 0;
}
.header-row .header .logo-item img {
  transition: all 0.3s;
  height: 77px;
}
.header-row .header .logo-item:hover img {
  transform: scale(1.1);
  transition: all 0.3s;
}
.header-row .header .logo-item svg {
  width: 210px;
  transition: all 0.3s;
}

.header-row .header ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  margin-left: 50px;
}

.header-row .header ul li {
  position: relative;
  margin-right: 25px;
}

.header-row .header ul li a {
  color: #fff;
  font-size: 14px;
  font-family: "helv65";
  text-decoration: none;
  position: relative;
  padding: 15px 0;
  transition: all 0.3s;
}
.header-row .header ul li a:after {
  content: "";
  border-radius: 10px 10px 0px 0px;
  background: #d0b478;
  height: 3px;
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}
.header-row .header ul li a.active:after {
  transition: all 0.3s;
  opacity: 1;
  visibility: visible;
}
.header-row .header ul li a:hover:after {
  transition: all 0.3s;
  opacity: 1;
  visibility: visible;
}

.header-row .header .right {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.header-row .header .right .btn-item {
  color: #dfc68b;
  font-size: 16px;
  font-family: "helv65";
  cursor: pointer;
  height: 54px;
  border: 1px solid rgba(208, 180, 120, 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
}
.header-row .header .right .btn-item:first-child {
  margin-right: 25px;
  background: #dfc68b;
  color: #000;
  padding: 10px 25px;
  border-radius: 10px;
  transition: all 0.3s;
}
.header-row .header .right .btn-item:hover {
  opacity: 0.6;
  transition: all 0.3s;
}
.header-row.footer-row .right a {
  margin-left: 15px;
  transition: all 0.3s;
}
.header-row.footer-row .right a:hover {
  transition: all 0.3s;
  opacity: 0.6;
}
.header-row.footer-row p {
  text-align: center;
  width: 100%;
  font-family: helv55;
  font-size: 16px;
  padding-top: 45px;
  padding-bottom: 55px;
  color: #fff;
}
.header-row.footer-row .header {
  background: linear-gradient(
    90deg,
    #424242 50.25%,
    #292929 70.13%,
    #424242 84.96%
  );
}
.header-row.footer-row .left .btn-item:hover {
  opacity: 0.6;
  transition: all 0.3s;
}

.main-area {
  width: 100%;
}
.main-area .main-area-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
.main-area .main-area-container .main-area-in {
  margin-top: 0;
  position: relative;
  width: 100%;
}
.center-img {
  position: relative;
  height: 730px;
  width: 100%;
}

.center-img img:first-child {
  width: 350px;
  margin-top: 50px;
  margin-left: 35px;
}
.center-img img:last-child {
  width: 100%;
  max-width: 53%;
  right: -58px;
  position: absolute;
  z-index: 4;
  top: -70px;
}
.main-area .main-area-container .main-area-in.rules {
  margin-top: 100px;
  max-width: 70%;
}
.main-area .main-area-container .main-area-in.rules h2 {
  font-size: 40px;
  color: #fff;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  margin-bottom: 25px;
}
.main-area .main-area-container .main-area-in.rules p {
  color: #fff !important;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 10px;
}
.main-area .main-area-container .main-area-in.rules ul, .main-area .main-area-container .main-area-in.rules ol {
  padding-left: 30px;
  margin-top: 15px;
}
.main-area .main-area-container .main-area-in.rules li {
  margin-bottom: 5px;
}
.main-area .main-area-container .main-area-in.rules p * {
  color: #fff !important;
}
.main-area .main-area-container .main-area-in.rules p h3 {
  font-feature-settings: "case";
  margin-bottom: 15px;
}
.main-area .main-area-container .main-area-in.rules p h4 {
  margin-bottom: 10px;
}

.main-area .main-area-container .main-area-in.rules .space-top {
  margin-top: 25px;
}
.main-area .main-area-container .main-area-in.rules .row-item-line {
  margin-bottom: 8px !important;
  line-height: 1.4;
}
.main-area .main-area-container .prizes-row {
  border-radius: 20px;
  width: 100%;
  height: 175px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(102, 96, 81, 1) 0%,
    rgba(54, 54, 54, 1) 100%
  );
  position: absolute;
  z-index: 2;
  top: 560px;
}
.main-area .main-area-container .prizes-row .swiper-side {
  width: 70%;
  padding-left: 35px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: -300px;
}
.main-area .main-area-container .prizes-row .swiper {
  position: relative;
  top: -45px;
  padding-bottom: 65px;
}
.main-area .main-area-container .prizes-row .swiper-side img {
  width: 100%;
}
.main-area .main-area-container .prizes-row .swiper-side .img {
  width: 100%;
  text-align: center;
  height: 310px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.main-area .main-area-container .prizes-row .swiper-side .slide-desc {
  text-align: center;
  width: 100%;
}
.main-area .main-area-container .prizes-row .swiper-side .slide-desc h4 {
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-family: "helv65" !important;
  line-height: normal;
  margin-top: 15px;
}

.main-area .main-area-container .prizes-row .swiper-side .slide-desc p {
  color: #fff;
  font-size: 14px;
  font-family: "helv55";
}
.main-area .main-area-container .prizes-row .swiper-pagination-bullet {
  background: #d9d9d9;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 28px;
  background: #d0b478 !important;
  border-radius: 5px;
}
.main-area .main-area-container .prizes-row .text-side {
  padding-left: 25px;
  padding-right: 35px;
  margin-left: 0px;
  margin-top: 15px;
}
.main-area .main-area-container .prizes-row .text-side h5 {
  color: #fff;
  font-size: 25px;
  font-family: "helv65";
  text-transform: uppercase;
  font-feature-settings: "case";
  letter-spacing: 0.5px;
}
.main-area .main-area-container .prizes-row .text-side p {
  color: #fff;
  font-size: 14px;
  font-family: "helv55";
  margin: 15px 0 25px;
  line-height: 1.4;
}
.main-area .main-area-container .prizes-row .text-side p div {
  display: inline-block;
  line-height: 17px;
}
.main-area .main-area-container .prizes-row .text-side p div div {
  color: #dfc68b;
  font-style: italic;
  cursor: pointer;
}
.main-area .main-area-container .prizes-row .text-side a {
  font-size: 14px;
  font-family: "helv65";
  color: #000;
  background: #dfc68b;
  padding: 10px 25px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.3s;
  display: inline-block;
}
.main-area .main-area-container .prizes-row .text-side a:hover {
  opacity: 0.6;
  transition: all 0.3s;
}

.collect-points-container {
  max-width: 1440px;
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 100px;
}

.how-to-container {
  max-width: 1440px;
  margin: 0 auto;
  /* background-image: url('../images/bg2-min.png'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 55px;
  margin-top: -65px;
  margin-bottom: 145px;
  padding-left: 15px;
  padding-right: 15px;
}
.how-to-container .how-to {
  width: 100%;
  padding-top: 80px;
}
.how-to-container .how-to .heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.how-to-container .how-to .heading svg {
  width: 100%;
}
.how-to-container .how-to .heading h3 {
  color: #fff;
  font-size: 36px;
  font-family: "helv65" !important;
  margin: 0;
  font-feature-settings: "case";
  text-align: center;
  max-width: 80%;
  line-height: 45px;
  margin-top: 80px;
}
.how-to-container .how-to .steps-row {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.collect-points {
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.collect-points h3 {
  font-size: 40px;
  font-family: "helv65";
  text-transform: uppercase;
  font-feature-settings: "case";
  color: #fff;
  max-width: 65%;
  line-height: 1.3;
}
.collect-points p {
  color: #d0b478;
  font-size: 16px;
  font-family: "helv65";
  margin-top: 30px;
  margin-bottom: 45px;
  width: 100%;
}
.collect-points .points {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.collect-points .points img {
  width: auto;
  height: 327px;
  margin-top: 80px;
}
.collect-points .points img:last-child {
  height: 210px;
}
.upload-row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  flex-direction: column;
  align-items: center;
}
.upload-row > svg {
  margin-top: 0;
  max-width: 290px;
}
.upload-row .upload-btn {
  cursor: pointer;
  transition: all 0.3s;
}
.upload-row .upload-btn:hover {
  opacity: 0.8;
  transition: all 0.3s;
}
.upload-row .upload-btn svg {
  width: 322px;
  height: 80px;
}

.leaderboards-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
.leaderbords {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  position: relative;
}
.leaderbords img {
  left: 50%;
  position: absolute;
  width: 40%;
  transform: translateX(-50%);
  bottom: 40px;
  z-index: 4;
}
.leaderboard-item {
  background-image: url("../images/leaderbg1.png");
  height: 735px;
  width: 880px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
.leaderboard-item .leaderboard-item-content {
  height: calc(770px - 105px);
  margin-top: 60px;
  background-image: url("../images/leaderbgcontent.png");
  width: calc(100% - 1px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin-left: 1px;
  border-top-right-radius: 0;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-direction: column;
}
.see-all {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.see-all .see-all-btn {
  background: #dfc68b;
  color: #000;
  padding: 15px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
  font-family: "helv65" !important;
}
.see-all .see-all-btn:hover {
  opacity: 0.6;
  transition: all 0.3s;
}
.user-data-item-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  padding-bottom: 15px;
}
.user-data-item-list .user-data-item {
  width: 535px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-bottom: 5px;
  min-height: 50px;
}
.user-data-item-list .user-data-item .index {
  min-width: 50px;
  width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
}
.user-data-item-list .user-data-item .user-info {
  width: calc(100% / 3);
  padding: 0 15px;
  color: #fff;
  font-size: 16px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  line-height: normal;
}
.user-data-item-list .user-data-item .user-info:not(:last-child):after {
  content: "";
  position: absolute;
  width: 1px;
  right: 0;
  height: 100%;
  background: rgba(239, 222, 175, 0.1);
}
.user-data-item-list .user-data-item .user-info:last-child {
  color: #e0c78c;
  font-size: 12px;
}
.leaderboard-item.full {
  border: 3px solid #efdeaf;
}
.leaderboard-item .see-history {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  position: relative;
}
.leaderboard-item .see-history .see-history-btn {
  color: #fff;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  right: 45px;
}
.leaderboard-item .see-history .full-button {
  background: rgba(224, 199, 140, 1);
  color: rgba(0, 0, 0, 1);
  border-radius: 10px;
  width: 176px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}
.leaderboard-item .see-history .full-button:hover {
  opacity: 0.7;
  transition: all 0.3s;
}
.leaderboard-item .leaderboard-header {
  position: absolute;
  top: 0;
  height: 60px;
  left: 0;
  width: 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0;
  overflow: hidden;
  border-top-right-radius: 20px;
}
.leaderboard-item .leaderboard-header h3 {
  color: #fff;
  font-size: 20px;
  font-family: "helv65" !important;
  margin: 0;
  font-feature-settings: "case";
}
.leaderboard-item .leaderboard-header p {
  position: relative;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: center;
}
.leaderboard-item .leaderboard-header p span {
  font-size: 16px;
  color: #fff;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  position: relative;
  z-index: 1;
}
.leaderboard-item .leaderboard-header p::after {
  content: "";
  background: #9e8b64;
  width: 108%;
  height: 224%;
  position: absolute;
  transform: rotate(-15deg);
}
.leaderboard-item.full .leaderboard-header p::after {
  background: rgba(208, 180, 120, 1);
}
.leaderboard-item.full .leaderboard-header {
  background: linear-gradient(160deg, #474030 0%, #9e8b64 100%);
}
.leaderboard-item.full {
  background: linear-gradient(
    90deg,
    rgba(71, 64, 49, 1) 0%,
    rgba(158, 139, 101, 1) 100%
  );
}

.modal-item-wrapper {
  position: fixed;
  background: #000000dd;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.modal-item-wrapper .modal-item-content {
  border-radius: 20px;
  background: #4d4d4d;
  width: 635px;
  position: relative;
  /* Drop Shadow */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-bottom: 5px solid#9E8B64;
}
.modal-item-wrapper .modal-item-content .itemimg {
  position: absolute;
  width: 200px;
  right: -50px;
  bottom: -12px;
}
.modal-item-wrapper .modal-item-content .bottles {
  position: absolute;
  left: -34%;
  width: 450px;
  bottom: 0;
}
.modal-item-wrapper .modal-item-content .logo {
  position: absolute;
  width: 170px;
  left: 50%;
  transform: translateX(-50%);
  top: -35px;
}
.modal-item-wrapper .modal-item-content .logo img {
  max-width: 100%;
}
.modal-item-wrapper .modal-item-content .close-modal {
  position: absolute;
  cursor: pointer;
  right: -20px;
  top: -20px;
  z-index: 5;
}

.modal-item-wrapper .modal-item-content .form-item {
  padding: 70px 100px 30px;
  position: relative;
  position: relative;
  z-index: 2;
}
.modal-item-wrapper .modal-item-content .form-item h3 {
  color: #fff;
  font-size: 25px;
  font-family: "helv65";
  font-feature-settings: "case";
  text-align: center;
  margin-bottom: 25px;
}
.modal-item-wrapper .modal-item-content .form-item .input-item {
  width: 100%;
  margin-bottom: 20px;
}
.modal-item-wrapper .modal-item-content .form-item .input-item input {
  border-radius: 16px;
  border: 1px solid rgba(208, 180, 120, 0.5);
  background: #444343;
  color: #d0b478;
  font-size: 14px;
  font-family: "helv55";
  height: 40px;
  outline: none;
  padding-left: 15px;
  width: 100%;
}
.modal-item-wrapper
  .modal-item-content
  .form-item
  .input-item
  input::placeholder {
  color: #d0b478;
}
.input-item [type="checkbox"] {
  width: 25px !important;
  height: 25px !important;
  margin-right: 10px;
  appearance: none;
  cursor: pointer;
}
.input-item [type="checkbox"]:checked {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTA3LjUwNiA1MDcuNTA2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MDcuNTA2IDUwNy41MDY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+CjxnPgoJPHBhdGggZmlsbD0iI2UwYzc4YyIgZD0iTTE2My44NjUsNDM2LjkzNGMtMTQuNDA2LDAuMDA2LTI4LjIyMi01LjcyLTM4LjQtMTUuOTE1TDkuMzY5LDMwNC45NjZjLTEyLjQ5Mi0xMi40OTYtMTIuNDkyLTMyLjc1MiwwLTQ1LjI0OGwwLDAgICBjMTIuNDk2LTEyLjQ5MiwzMi43NTItMTIuNDkyLDQ1LjI0OCwwbDEwOS4yNDgsMTA5LjI0OEw0NTIuODg5LDc5Ljk0MmMxMi40OTYtMTIuNDkyLDMyLjc1Mi0xMi40OTIsNDUuMjQ4LDBsMCwwICAgYzEyLjQ5MiwxMi40OTYsMTIuNDkyLDMyLjc1MiwwLDQ1LjI0OEwyMDIuMjY1LDQyMS4wMTlDMTkyLjA4Nyw0MzEuMjE0LDE3OC4yNzEsNDM2Ljk0LDE2My44NjUsNDM2LjkzNHoiLz4KPC9nPgoKCgoKCgoKCgoKCgoKCgo8L3N2Zz4K") !important;
  background-position: center !important;
  background-size: 13px !important;
  background-repeat: no-repeat !important;
}
.input-item label {
  display: flex;
  align-items: center;
  color: #e0c78c;
  font-size: 14px;
}
.input-item label a {
  color: #e0c78c;
  margin-left: 3px;
}
.modal-item-wrapper .modal-item-content .form-item .action-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}
.modal-item-wrapper .modal-item-content .form-item .action-btns .action-btn {
  border-radius: 10px;
  background: #e0c78c;
  height: 50px;
  /* Drop Shadow */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 16px;
  font-family: "helv65";
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.modal-item-wrapper
  .modal-item-content
  .form-item
  .action-btns
  .action-btn:hover {
  opacity: 0.6;
  transition: all 0.3s;
}
.modal-item-wrapper
  .modal-item-content
  .form-item
  .action-btns
  .action-btn:last-child {
  background: none;
  color: #e0c78c;
  text-decoration: underline;
  margin-top: 15px;
  box-shadow: none;
}

@media (max-width: 1700px) {
  .header-row .header .logo-item svg {
    width: 180px;
  }
  .header-row .header ul {
    margin-left: 50px;
  }
  .header-row .header ul li {
    margin-right: 35px;
  }
  .header-row .header ul li a {
    font-size: 14px;
  }
  .header-row .header .right .btn-item {
    font-size: 14px;
  }
}
@media (max-width: 1400px) {
  .header-row .header ul li {
    margin-right: 15px;
  }
  .header-row .header .logo-item svg {
    width: 150px;
  }
  .header-row .header ul li a {
    font-size: 14px;
  }
  .center-img img:first-child {
    max-width: 57%;
  }
}
@media (max-width: 1350px) {
  .leaderbords img {
    display: none;
  }
}
@media (max-width: 1210px) {
  .header-row .header ul li {
    margin-right: 15px !important;
  }
  .header-row .header .logo-item svg {
    width: 130px;
  }
  .header-row .header ul li a {
    font-size: 11px;
  }
  .container-item {
    max-width: calc(100% - 30px);
  }
  .header-row .header .right .btn-item {
    font-size: 11px;
  }
}
@media (max-width: 991px) {
  .modal-item-wrapper .modal-item-content .bottles {
    display: none;
  }
  .modal-item-wrapper .modal-item-content .itemimg {
    display: none;
  }
  .modal-item-wrapper .modal-item-content .form-item {
    padding: 70px 25px 25px;
  }
  .modal-item-wrapper .modal-item-content {
    width: calc(100% - 30px);
  }
  .modal-item-wrapper .modal-item-content .logo {
    display: flex;
    justify-content: center;
  }
  .modal-item-wrapper .modal-item-content .logo svg {
    width: 150px;
  }
  .modal-item-wrapper .modal-item-content .form-item h3 {
    font-size: 20px;
  }
  .modal-item-wrapper .modal-item-content .form-item .input-item input {
    font-size: 14px;
  }
}

.user-profile-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}
.user-profile-wrapper .user-profile-content {
  margin-top: 100px;
}
.user-profile-wrapper h2 {
  font-size: 40px;
  color: #fff;
  font-family: "helv65";
  font-feature-settings: "case";
}
.user-profile-wrapper .notification {
  border-radius: 10px;
  border: 1px dashed #d0b478;
  padding: 50px;
  display: flex;
  align-items: center;
  margin-top: 35px;
  justify-content: space-between;
}
.user-profile-wrapper .notification svg {
  margin-right: 45px;
}
.user-profile-wrapper .notification p {
  color: #fff;
  font-size: 16px;
  font-family: "helv55";
}
.user-profile-wrapper .notification .left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-profile-wrapper .notification .close {
  color: #d0b478;
  font-family: "helv55";
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
}
.user-profile-wrapper .my-data {
  border-radius: 20px;
  background: rgba(98, 98, 98, 1);
  padding: 42px;
  /* Drop Shadow */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin-top: 70px;
  width: 100%;
}
.user-profile-wrapper .my-data .headline {
  display: flex;
  width: 100%;
  align-items: center;
}
.user-profile-wrapper .my-data .headline div {
  display: flex;
  align-items: center;
}

.user-profile-wrapper .my-data .headline h4 {
  color: #fff;
  font-size: 25px;
  font-family: "helv65" !important;
  font-feature-settings: "case";
}
.user-profile-wrapper .my-data .headline p {
  border-radius: 20px;
  background: #d0b478;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  padding: 8px 25px;
  margin-left: 15px;
}
.user-profile-wrapper .my-data .upload-area {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  border-radius: 10px;
  border: 1px dashed #d0b478;
  background: rgba(0, 0, 0, 0.2);
  padding: 50px;
  position: relative;
  transition: all 0.3s;
}
.user-profile-wrapper .my-data .upload-area.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.user-profile-wrapper .my-data .upload-area:hover {
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}
.user-profile-wrapper .my-data .upload-area .upload-placeholder {
}
.user-profile-wrapper .my-data .upload-area .upload-placeholder svg {
}
.user-profile-wrapper .my-data .receipt-list {
  display: inline-block;
  width: 100%;
}
.spinner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3f3f3fa6;
  color: #fff;
  font-size: 16px;
}
.spinner-wrapper div {
  margin-right: 10px;
}
.user-profile-wrapper .my-data .receipt-list .list-item {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  margin-top: 25px;
  padding: 15px;
}
.user-profile-wrapper .my-data .receipt-list .list-item .list-item-info {
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  font-size: 14px;
}
.user-profile-wrapper
  .my-data
  .receipt-list
  .list-item
  .list-item-info:nth-child(4) {
  text-align: center;
  color: #fff;
  font-family: "helv65" !important;
  font-feature-settings: "case";
}
.user-profile-wrapper
  .my-data
  .receipt-list
  .list-item
  .list-item-info:nth-child(3) {
  color: #e0c78c;
  font-family: "helv65" !important;
}
.user-profile-wrapper
  .my-data
  .receipt-list
  .list-item
  .list-item-info:nth-child(4) {
  text-transform: capitalize;
}
.user-profile-wrapper .my-data .receipt-list .list-item .list-item-info > img {
  max-width: 50px;
}
.user-profile-wrapper
  .my-data
  .receipt-list
  .list-item
  .list-item-info
  .popover {
  display: none;
  position: absolute;
  right: -150px;
  border-radius: 15px !important;
  overflow: hidden;
  padding: 0 !important;
}
.user-profile-wrapper
  .my-data
  .receipt-list
  .list-item
  .list-item-info
  > img:hover
  + .popover {
  display: block;
}
.user-profile-wrapper
  .my-data
  .receipt-list
  .list-item
  .list-item-info
  .popover
  img {
  max-width: 200px;
}
.user-profile-wrapper
  .my-data
  .receipt-list
  .list-item
  .list-item-info:last-child {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}
.user-profile-wrapper aside {
  flex-direction: column !important;
}
.user-profile-wrapper aside .remove {
  background: red;
  color: #fff;
  padding: 5px 0;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  width: 100px;
  margin-top: 8px;
}
.general-notificaiton {
  min-height: 50vh;
}
.general-notificaiton .alert {
  background: transparent;
  border: 1px dashed #a1916e;
  padding: 25px;
  color: #a1916e;
  font-size: 18px;
}
.invalid-item {
  border-color: red !important;
}

.modal-item-wrapper
  .modal-item-content
  .form-item
  .input-item.invalid-item
  input {
  border-color: red !important;
}
.alert {
  line-height: normal;
  font-size: 13px;
  font-family: "helv55" !important;
}
.alert-dismissible .btn-close {
  height: auto;
}
.show-more-row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 45px;
}
.show-more-row button {
  padding: 12px 35px;
  border: none;
  border-radius: 5px;
  background: #9e8b64;
  color: #fff;
  font-size: 14px;
}
.new-user-registration {
  padding: 25px;
}
.mechanic-container-item {
  width: 100%;
}
.mechanic-steps {
  width: 100%;
  max-width: 1440px;
  margin: 70px auto 0;
  padding: 0 15px;
}
.mechanic-steps h4 {
  color: #d0b478;
  font-size: 20px;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  text-align: center;
  margin: 100px auto;
  max-width: 100%;
  line-height: 1.4;
}
.mechanic-steps h4 svg:last-child {
  display: none;
}
.mechanic-steps h4 span {
  color: #d0b478;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  font-size: 20px;
}
.mechanic-steps h5 {
  color: #d0b478;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  font-size: 20px;
  max-width: 100%;
  text-align: center;
  width: 100%;
  padding: 0 18%;
  line-height: 1.3;
  margin-top: 50px;
}
.mechanic-steps .heading-item {
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.mechanic-steps .heading-item svg {
}
.mechanic-steps .steps-row {
  background-image: url("../images/stepsbg.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 319px;
  margin-top: 50px;
}
.how-does-it-work-container-item {
  width: 100%;
  position: relative;
}
.how-does-it-work-container-item .bottles {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 39%;
}
.how-does-it-work-container-item .item2 {
  position: absolute;
  top: -75px;
  left: 0;
  max-width: 200px;
}
.how-does-it-work-container-item .how-does-it-work {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto 0;
  padding: 100px 15px 0;
  position: relative;
  z-index: 2;
}
.how-does-it-work-container-item .how-does-it-work h4 {
  color: #fff;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  font-size: 24px;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  color: rgba(208, 180, 120, 1);
  line-height: 30px;
}
.how-does-it-work-container-item .how-does-it-work .points {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.how-does-it-work-container-item .how-does-it-work .points img {
  height: 390px;
}
.how-does-it-work-container-item .how-does-it-work .generals {
  border-radius: 10px;
  border: 1px dashed #d0b478;
  padding: 25px 50px;
  margin-top: 100px;
  padding-right: 100px;
}
.how-does-it-work-container-item .how-does-it-work .generals h3 {
  color: #fff;
  font-size: 36px;
  text-align: center;
  font-family: "helv65" !important;
  font-feature-settings: "case" on;
  margin-top: 15px;
  margin-bottom: 20px;
}
.how-does-it-work-container-item .how-does-it-work .generals .bottom-row-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.how-does-it-work-container-item
  .how-does-it-work
  .generals
  .bottom-row-in
  img {
  max-width: 40%;
}
.how-does-it-work-container-item
  .how-does-it-work
  .generals
  .bottom-row-in
  .rg {
  max-width: 50%;
  margin-top: 50px;
}
.how-does-it-work-container-item
  .how-does-it-work
  .generals
  .bottom-row-in
  .rg
  h4 {
  margin: 0;
  text-align: left;
}
.how-does-it-work-container-item .how-does-it-work .generals ul {
  list-style-type: none;
}
.how-does-it-work-container-item .how-does-it-work .generals ul li {
  color: #fff;
  font-size: 16px;
  margin: 20px 0;
  line-height: 1.5;
  position: relative;
  display: flex;
  align-items: center;
}
.how-does-it-work-container-item .how-does-it-work .generals ul li span {
  display: inline;
}
.how-does-it-work-container-item .how-does-it-work .generals ul li::before {
  content: "";
  min-width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #d0b478;
  margin-right: 30px;
  display: inline-block;
}
.what-can-to-win-container-item {
  width: 100%;
}
.what-can-to-win-container-item .what-can-to-win {
  width: 100%;
  max-width: 1440px;
  margin: 70px auto 0;
  padding: 0 15px;
  position: relative;
  z-index: 1;
}
.what-can-to-win-container-item .what-can-to-win > h3 {
  text-align: center;
  color: #fff;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  font-size: 30px;
}
.what-can-to-win-container-item .what-can-to-win .two-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}
.two-boxes .box-item {
  background: linear-gradient(
    90deg,
    rgba(65, 65, 65, 1) 0%,
    rgba(128, 118, 101, 1) 100%
  );
  width: calc(100% / 2 - 15px);
  border-radius: 20px;
  height: 650px;
  display: flex;
  flex-direction: column;
}
.two-boxes .box-item > h3 {
  text-align: center;
  color: #d0b478;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  font-size: 20px;
  padding: 15px 0;
  margin-bottom: 0;
}
.two-boxes .box-item .box-item-content {
  background: linear-gradient(
    90deg,
    rgb(53 53 53) 0%,
    rgba(113, 106, 92, 1) 100%
  );
  border-radius: 20px;
  padding: 30px;
  flex: 1;
}
.two-boxes .box-item .box-item-content > p {
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  height: 110px;
  display: inline-block;
}
.two-boxes .box-item .box-item-content > p span {
  font-family: "helv-65" !important;
  font-weight: bold;
}
.two-boxes .box-item .box-item-content .box-list {
  width: 100%;
  margin-top: 40px;
}
.two-boxes .box-item .box-item-content .box-list .box-list-item {
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 15px;
  align-items: center;
}
.two-boxes
  .box-item
  .box-item-content
  .box-list
  .box-list-item:not(:last-child) {
  margin-bottom: 15px;
}
.two-boxes .box-item .box-item-content .box-list .box-list-item .circ {
  width: 50px;
  height: 50px;
  background: #efdeaf;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "helv65" !important;
  border-radius: 100%;
  position: relative;
  font-size: 15px;
}
.two-boxes .box-item .box-item-content .box-list .box-list-item .rg {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: center;
}
.two-boxes .box-item .box-item-content .box-list .box-list-item .rg h4 {
  color: #d0b478;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  font-size: 16px;
  margin-bottom: 0;
}
.two-boxes .box-item .box-item-content .box-list .box-list-item .rg p {
  color: #d0b478;
  font-size: 12px;
  margin-bottom: 0;
}
.two-boxes .cols {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
}
.two-boxes .coll {
  width: calc(100% / 2 - 15px);
  background: rgb(0 0 0 / 20%);
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.two-boxes .coll img {
  max-width: 100%;
}
.two-boxes .coll p {
  color: #d0b478 !important;
  font-size: 12px !important;
  text-align: center;
  line-height: normal !important;
  padding: 0 15px;
  margin-bottom: 18px;
}
.two-boxes .coll h4 {
  color: #d0b478;
  font-family: "helv65" !important;
  font-feature-settings: "case" !important;
  font-size: 16px;
  text-transform: uppercase;
}
.period-container-item {
  width: 100%;
}
.period-container-item .period-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 70px auto 0;
  padding: 0 15px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.period-container-item .period-wrapper .play-period {
  border-radius: 10px;
  border: 1px dashed #d0b478;
  padding: 25px 50px;
  margin-top: 20px;
  margin-right: 20px;
  width: 48%;
  height: 290px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.period-container-item .period-wrapper .play-period h3 {
  color: #fff;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  font-size: 20px;
  margin-bottom: 0;
  color: rgba(246, 38, 23, 1);
  line-height: 24px;
}
.period-container-item .period-wrapper .play-period h4 {
  color: #d0b478;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  font-size: 20px;
  margin-bottom: 25px;
}
.period-container-item ul {
  list-style-type: none;
}
.period-container-item ul li {
  color: #fff;
  font-size: 16px;
  margin: 20px 0;
  line-height: 1.5;
  position: relative;
  display: flex;
  align-items: flex-start;
}
.period-container-item ul li::before {
  content: "";
  min-width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #d0b478;
  margin-right: 30px;
  display: inline-block;
  position: relative;
  top: 5px;
}
.period-container-item .what-happens {
  border-radius: 10px;
  border: 1px dashed #d0b478;
  padding: 25px 50px;
  margin-top: 20px;
  margin-left: 20px;
  width: 48%;
  height: 290px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.period-container-item .what-happens p {
  color: #fff;
  margin-top: 0;
  font-size: 16px;
  line-height: 26px;
}
.period-container-item .what-happens p span {
  display: inline-block;
  margin-left: 5px;
  color: #d0b478;
  line-height: normal;
}
.period-container-item .what-happens h3 {
  color: #fff;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  font-size: 28px;
  margin-bottom: 25px;
}
.period-container-item .what-happens ul li {
  font-size: 16px;
  margin: 9px 0 !important;
}
.period-container-item svg {
  margin-top: 30px;
  float: right;
  position: relative;
  top: 25px;
}
.additional-box {
  border-radius: 20px;
  max-width: 70%;
  margin: 100px auto 50px;
  position: relative;
}
.additional-box h3 {
  color: #fff;
  font-family: "helv65" !important;
  font-size: 36px;
  padding: 25px 30px;
  margin-bottom: 0;
  font-feature-settings: "case";
  text-align: center;
}
.additional-box .additional-box-content {
  padding: 30px;
  border-bottom-left-radius: 20px;
  padding-right: 40px;
  border-bottom-right-radius: 20px;
}
.additional-box .additional-box-content li:first-child {
  margin-top: 0;
}
.additional-box img {
  right: -120px;
  position: absolute;
  bottom: -75px;
  max-width: 300px;
}
.prizes-container-item {
  width: 100%;
}
.prizes-container-item .head {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 85px;
}
.prizes-container-item .head img {
  width: 290px;
}
.prizes-container-item .head svg {
  margin-top: 35px;
}
.prizes-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 70px auto 0;
  padding: 0 15px;
}
.prizes-wrapper .prizes-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 100px;
}
.prizes-wrapper .prizes-list .prize {
  width: calc(100% / 3 - 15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  height: 350px;
  background: rgba(98, 98, 98, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  justify-content: flex-end;
  padding: 0 25px 45px;
  margin-bottom: 120px;
  position: relative;
}
.prizes-wrapper .prizes-list .prize .amn {
  width: 80px;
  height: 80px;
  background: #efdeaf;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "helv65" !important;
  border-radius: 100%;
  position: absolute;
  font-size: 30px;
  right: 15px;
  top: -30px;
  z-index: 5;
}
.prizes-wrapper .prizes-list .prize:nth-of-type(8) img {
  max-width: 70% !important;
}

.prizes-wrapper .prizes-list .prize:nth-of-type(8) {
  margin-bottom: 15px;
}
.prizes-wrapper .prizes-list .prize img {
  max-width: 90%;
  position: absolute;
  top: -20px;
}
.prizes-wrapper .prizes-list .prize:nth-child(3) img {
  max-width: 70%;
}
@media (max-width: 991px) {
  .prizes-wrapper .prizes-list .prize:nth-of-type(8) img {
    max-width: 300px !important;
  }
  .prizes-wrapper .prizes-list .prize:nth-child(3) img {
    max-width: 300px;
  }
}
.prizes-wrapper .prizes-list .prize h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "helv65" !important;
  line-height: 1.5;
}
.prizes-wrapper .prizes-list .prize p {
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin-top: 15px;
  line-height: 1.3;
}
.collect-and-win {
  margin-left: 0;
  border: 1px dashed #d0b478;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  width: calc(100% / 3 - 15px);
  padding: 35px;
  height: 350px;
}
.collect-and-win:hover {
  opacity: 0.6;
  transition: all 0.3s;
}

.winners-container-item {
  background-image: url("../images/generalbg-min.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  margin-bottom: 100px;
}
.winners-wrapper {
  /* background-image: url('../images/bottles3-min.png'); */
  background-size: contain;
  background-position: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto 0;
  padding: 100px 15px 0;
}
.winners-wrapper h2 {
  font-size: 40px;
  color: #fff;
  font-family: "helv65" !important;
  font-feature-settings: "case";
}
.winners-wrapper .winners-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  width: 100%;
  z-index: 2;
  position: relative;
}
.winners-wrapper .winners-boxes .lf {
  display: flex;
  width: 55%;
  align-items: flex-start;
}
.winners-wrapper .winners-boxes .lf .choose-period {
  display: flex;
  flex-wrap: wrap;
}
.winners-wrapper .winners-boxes .lf .choose-period h5 {
  color: #fff;
  font-size: 14px;
  width: 100%;
  margin-bottom: 15px;
}
.choose-box {
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #d0b478;
  padding: 10px;
  max-width: 340px;
}
.choose-box .dt {
  border-radius: 12px;
  background: #d0b478;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: #000;
  font-family: "helv65" !important;
  font-size: 14px;
  white-space: nowrap;
}
.choose-box .dr-item {
  position: relative;
  width: 210px;
  margin-left: 40px;
  z-index: 2;
  padding-right: 15px;
}
.choose-box .dr-item .active-value {
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  cursor: pointer;
  height: 40px;
  white-space: nowrap;
}
.choose-box .dr-item .active-value svg {
  margin-left: 5px;
}
.choose-box .dr-items {
  position: absolute;
  width: 100%;
  border-radius: 8px;
  padding-top: 15px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.3s;
}
.choose-box .dr-item:hover .dr-items {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
.choose-box .dr-items .item {
  padding: 15px;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  background: #fff;
  transition: all 0.3s;
}
.choose-box .dr-items .item:hover {
  opacity: 0.9;
  transition: all 0.3s;
}

.winners-wrapper .winners-boxes .rg {
  width: 45%;
  padding-left: 45px;
}
.winners-wrapper .winners-boxes .rg .leaderboard-item {
  width: 100%;
  margin-top: 30px;
}
.winners-wrapper
  .winners-boxes
  .rg
  .leaderboard-item
  .leaderboard-item-content {
  height: calc(770px - 60px);
}
.winners-wrapper .winners-boxes .lf .places {
  display: flex;
  margin-top: -45px;
  justify-content: space-between;
  align-items: flex-end;
}
.winners-wrapper .winners-boxes .lf .places .pl-item {
  position: relative;
}
.winners-wrapper .winners-boxes .lf .places .pl-item:first-child {
  width: 400px;
  position: relative;
}
.winners-wrapper .winners-boxes .lf .places .pl-item:not(:last-child) {
  margin-right: 30px;
}
.winners-wrapper .winners-boxes .lf .places .pl-item > svg {
  width: 100%;
  height: 550px;
}
.winners-wrapper .winners-boxes .lf .places .pl-item .user-info {
  position: absolute;
  z-index: 3;
  top: 37%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}
.winners-wrapper .winners-boxes .lf .places .pl-item:nth-child(2) .user-info {
  top: 32%;
}
.winners-wrapper .winners-boxes .lf .places .pl-item:last-child .user-info {
  top: 37%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.winners-wrapper
  .winners-boxes
  .lf
  .places
  .pl-item:last-child
  .user-info
  .lf-info {
  margin-right: 50px;
  text-align: left;
}
.winners-wrapper .winners-boxes .lf .places .pl-item .user-info img {
  max-width: 135px !important;
}
.winners-wrapper .winners-boxes .lf .places .pl-item .user-info h5 {
  font-size: 16px;
  color: #efdeaf;
  margin-bottom: 10px;
}
.winners-wrapper .winners-boxes .lf .places .pl-item .user-info h4 {
  font-size: 18px;
  color: #efdeaf;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  margin-bottom: 20px;
}
.winners-wrapper .winners-boxes .lf .places .pl-item .user-info h2 {
  font-size: 30px;
  color: #efdeaf;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  margin-bottom: 25px;
}
.winners-wrapper .winners-boxes .lf .places .pl-item .user-info p {
  margin-top: 100px;
  color: #efdeaf;
  font-size: 16px;
}
.winners-wrapper .winners-boxes .lf .places .pl-item .user-info img {
  max-width: 70px;
}
.winners-wrapper .winners-boxes .lf .places .title {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "helv65" !important;
  text-align: center;
  margin-top: 25px;
  line-height: normal;
  height: 40px;
}
.sm-img {
  max-width: 35px !important;
}
.md-img {
  max-width: 50px !important;
}
.additional-box .additional-box-content li {
  color: #fff;
  font-size: 16px;
  margin: 20px 0;
  line-height: 1.5;
  position: relative;
  display: flex;
  align-items: center;
}
.additional-box .additional-box-content li:nth-child(3) {
  flex-wrap: wrap;
  color: rgba(205, 183, 136, 1);
}
.additional-box .additional-box-content li::before {
  content: "";
  min-width: 5px;
  height: 5px;
  border-radius: 12px;
  background: #ffffff;
  margin-right: 15px;
  display: inline-block;
}
.additional-box .additional-box-content li p {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
  margin-left: 15px;
  color: rgba(205, 183, 136, 1);
  font-style: italic;
}

@media (max-height: 800px) {
  .modal-item-wrapper .modal-item-content .logo {
    width: 125px;
  }
  .modal-item-wrapper .modal-item-content .form-item {
    padding-top: 50px;
  }
  .modal-item-wrapper .modal-item-content .form-item .input-item {
    margin-bottom: 12px;
  }
  .modal-item-wrapper .modal-item-content .bottles {
    left: -27%;
    width: 375px;
  }
}
@media (max-height: 690px) {
  .modal-item-wrapper .modal-item-content .itemimg {
    display: none;
  }
  .modal-item-wrapper .modal-item-content .bottles {
    display: none;
  }
  .modal-item-wrapper .modal-item-content .form-item {
    padding-bottom: 5px;
  }
}
@media (max-width: 1500px) {
  .container-item {
    width: 1280px;
  }
  .header-row .header ul li {
    margin-right: 25px;
  }
  .main-area .main-area-container {
    width: 1280px;
  }
  .leaderboards-container,
  .how-to-container,
  .mechanic-steps,
  .how-does-it-work,
  .what-can-to-win,
  .period-wrapper,
  .user-profile-wrapper {
    width: 1280px !important;
  }
  .leaderbords img {
    width: 33%;
  }
  .how-does-it-work-container-item .bottles {
    bottom: -41%;
    max-width: 36%;
  }
  .period-container-item .what-happens {
    width: 55%;
  }
  .period-container-item svg {
    margin-top: 20px;
    top: 0;
    width: 110px;
  }
  .prizes-wrapper {
    width: 1280px;
  }
}

@media (max-width: 1300px) {
  .main-area .main-area-container,
  .prizes-wrapper,
  .leaderboards-container,
  .how-to-container,
  .mechanic-steps,
  .how-does-it-work,
  .what-can-to-win,
  .period-wrapper,
  .container-item,
  .user-profile-wrapper {
    max-width: unset;
    padding: 0 15px;
    width: 100% !important;
  }
  .how-does-it-work-container-item .bottles {
    display: none;
  }
  .period-container-item .period-wrapper {
    flex-direction: column;
  }
  .period-container-item .period-wrapper .play-period {
    width: 100%;
    height: auto;
    padding: 25px;
    margin-right: 0;
  }
  .period-container-item svg {
    display: none;
  }
  .period-container-item .what-happens {
    width: 100%;
    margin-left: 0;
    height: auto;
  }
  .additional-box {
    max-width: unset;
    width: 100%;
  }
  .additional-box img {
    display: none;
  }
  .what-can-to-win-container-item .what-can-to-win .two-boxes {
    flex-direction: column;
  }
  .two-boxes .box-item {
    width: 100%;
    height: auto;
  }
  .two-boxes .box-item:first-child {
    margin-bottom: 25px;
  }
  .two-boxes .box-item .box-item-content > p {
    height: auto;
  }
  .two-boxes .cols {
    flex-direction: column;
  }
  .two-boxes .coll {
    height: auto;
    width: 100%;
    padding-bottom: 25px;
  }
  .two-boxes .coll:first-child {
    margin-bottom: 25px;
  }
  .additional-box h3 {
    line-height: 30px;
  }
  .winners-wrapper .winners-boxes {
    flex-direction: column;
  }
  .winners-wrapper .winners-boxes .lf {
    width: 100%;
  }
  .winners-wrapper .winners-boxes .rg {
    width: 100%;
    padding-left: 0;
    margin-top: 50px;
  }
  .winners-wrapper {
    background: none;
  }
}
@media (min-width: 992px) and (max-width: 1300px) {
  .winners-wrapper .winners-boxes .lf .places .pl-item:first-child .title {
    margin-top: 25px !important;
  }
}
@media (max-width: 991px) {
  .prizes-wrapper .prizes-list {
    flex-direction: column;
  }
  .prizes-wrapper .prizes-list .prize {
    width: 100%;
  }
  .prizes-container-item .head img {
    width: 60%;
  }
  .prizes-container-item .head svg {
    width: 70%;
  }
  .prizes-wrapper {
    margin-top: 100px;
  }
  .winners-wrapper h2 {
    font-size: 24px;
  }
  .winners-wrapper .winners-boxes .lf .places {
    flex-direction: column;
    margin-top: 50px;
  }
  .winners-wrapper {
    margin-bottom: 100px;
  }
  .winners-wrapper .winners-boxes .lf .places .pl-item {
    width: 100% !important;
    margin-bottom: 70px !important;
  }
  .winners-wrapper .winners-boxes .lf .places .pl-item:first-child {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .winners-wrapper .winners-boxes .lf .choose-period .choose-box .dt {
    white-space: nowrap;
    font-size: 12px;
    padding: 0 15px;
  }
  .winners-wrapper .winners-boxes .lf .choose-period .choose-box .dr-item {
    width: 135px;
    margin-left: 25px;
  }
  .winners-wrapper .winners-boxes .lf .places .pl-item:nth-child(2) {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .winners-wrapper .winners-boxes .lf .places .pl-item:nth-child(3) {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .winners-wrapper .winners-boxes .lf .places .title {
    margin-top: 25px !important;
  }
  .winners-wrapper {
    padding-top: 50px;
  }
  .collect-and-win {
    margin-left: 0;
    padding-top: 35px;
    padding-bottom: 35px;
    margin-top: 35px;
    width: 100%;
  }
  .collect-and-win svg {
    width: 85%;
  }
  .winners-wrapper .winners-boxes .rg .leaderboard-item {
    height: 500px;
  }
  .winners-wrapper
    .winners-boxes
    .rg
    .leaderboard-item
    .leaderboard-item-content {
    height: calc(500px - 60px);
  }
  .header-row .header .left ul {
    display: none;
  }
  .header-row .header {
    margin-top: 15px;
    height: 55px;
    padding-right: 0;
    border-radius: 10px;
  }
  .mobile-button {
    margin-left: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .header-row:not(.footer-row) .left {
    position: absolute;
    left: 75px;
    top: -12px;
  }
  .header-row:not(.footer-row) .header .logo-item {
    margin: 0;
    display: none;
  }
  .header-row .header .logo-item svg {
    width: 110px !important;
  }
  .fs-menu {
    position: fixed;
    top: 8px;
    left: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    background: #424242;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .fs-menu .btn-item {
    color: #d0b478;
    text-decoration: none;
    font-size: 20px;
    font-family: "helv65" !important;
    font-feature-settings: "case";
  }
  .fs-menu .close {
    position: absolute;
    top: 15px;
    left: 15px;
  }
  .fs-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .fs-menu ul li {
    margin: 25px 0;
    text-align: center;
  }
  .fs-menu ul li a {
    color: #d0b478;
    text-decoration: none;
    font-size: 20px;
    font-family: "helv65" !important;
    font-feature-settings: "case";
  }

  .footer-row .header {
    height: 75px;
  }
  .header-row.footer-row .header .logo-item {
    margin-top: 40px;
  }
  .authed-user .abbtr {
    width: 40px;
    height: 40px;
  }
  .authed-user .wellcome {
    margin-right: 15px;
  }
  .center-img img:first-child {
    max-width: 320px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  .center-img {
    height: 500px;
  }
  .main-area .main-area-container .prizes-row {
    top: 370px;
    height: 180px;
    flex-direction: column;
  }

  .main-area .main-area-container .prizes-row .swiper-side .img {
    height: 130px;
  }
  .main-area .main-area-container .prizes-row .swiper {
    padding-bottom: 40px;
  }
  .main-area .main-area-container .prizes-row .swiper-side .slide-desc h4 {
    font-size: 12px;
  }
  .main-area .main-area-container .prizes-row .swiper-side {
    width: 100%;
    padding-left: 0;
  }
  .main-area .main-area-container .prizes-row .swiper-side img {
    width: auto;
    height: 180px;
  }
  .main-area .main-area-container .prizes-row .text-side {
    padding-left: 0;
    padding-right: 0;
    margin-top: -20px;
  }
  .main-area .main-area-container {
    padding: 0 !important;
  }
  .collect-points h3 {
    max-width: 100%;
    font-size: 20px;
  }
  .collect-points-container {
    padding: 60px 15px 0;
  }
  .collect-points p {
    font-size: 16px;
    font-family: "helv65" !important;
    margin-top: 20px;
    width: 100%;
    line-height: 22px;
  }
  .desk {
    display: none;
  }
  .collect-points .points img:last-child {
    width: 100%;
    height: auto;
    margin-top: 45px;
  }

  .upload-row {
    margin-top: 55px;
  }
  .leaderboard-item .leaderboard-header {
    padding: 0 15px;
    padding-right: 0;
  }
  .leaderboard-item .leaderboard-header p::after {
    width: 125%;
  }
  .leaderboard-item .leaderboard-header p span {
    font-size: 12px;
  }
  .leaderboard-item .leaderboard-header p {
    width: 150px;
  }
  .leaderboard-item .leaderboard-header h3 {
    font-size: 12px;
  }
  .user-data-item-list .user-data-item .user-info {
    font-size: 14px;
  }
  .leaderboard-item .leaderboard-item-content {
    height: calc(500px - 55px);
  }
  .leaderboard-item {
    height: 500px;
  }
  .leaderboard-item .see-history {
    display: none;
  }
  .see-all .see-all-btn {
    font-size: 14px;
  }
  .steps-row {
    /* background-image: url('../images/stepsmob-min.png') !important; */
    height: 600px !important;
    margin-top: 25px;
  }
  .modal-item-wrapper .modal-item-content .close-modal {
    right: -7px;
    top: -22px;
  }
  .modal-item-wrapper .modal-item-content .close-modal svg {
    width: 35px;
  }
  .input-item label {
    font-size: 10px;
  }
  .modal-item-wrapper .modal-item-content .form-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .user-profile-wrapper .user-profile-content {
    margin-top: 25px;
  }
  .user-profile-wrapper h2 {
    font-size: 20px;
  }
  .user-profile-wrapper .my-data {
    padding: 15px;
    margin-bottom: 50px;
    margin-top: 25px;
  }
  .user-profile-wrapper .my-data .headline h4 {
    font-size: 12px;
  }
  .user-profile-wrapper .my-data .headline p {
    padding: 5px 15px;
    font-size: 14px;
  }
  .user-profile-wrapper .my-data .receipt-list .list-item {
    overflow-x: auto;
    padding: 15px;
  }
  .user-profile-wrapper
    .my-data
    .receipt-list
    .list-item
    .list-item-info
    .popover {
    display: none !important;
  }
  .mechanic-steps .heading-item {
    margin-top: 0;
  }
  .mechanic-steps h5 {
    margin-top: 0;
    max-width: unset;
    font-size: 16px;
    padding: 0 15px;
    line-height: 25px;
  }
  .how-does-it-work-container-item .how-does-it-work h4 {
    font-size: 14px;
    line-height: 22px;
    text-align: center !important;
    max-width: 100%;
  }
  .how-does-it-work-container-item .how-does-it-work .generals {
    padding: 25px 20px;
    padding-right: 20px;
  }
  .how-does-it-work-container-item .how-does-it-work .generals ul li {
    font-size: 14px;
  }
  .what-can-to-win-container-item .what-can-to-win > h3 {
    font-size: 24px;
  }
  .two-boxes .box-item .box-item-content {
    padding: 15px;
  }
  .two-boxes .box-item .box-item-content .box-list .box-list-item .rg h4 {
    font-size: 14px;
    line-height: 20px;
  }
  .period-container-item .what-happens {
    padding: 25px;
  }
  .period-container-item .what-happens h3 {
    line-height: 26px;
  }
  .period-container-item ul li {
    font-size: 14px;
  }
  .additional-box .additional-box-content {
    padding: 25px;
  }
  .additional-box {
    margin-top: 35px;
  }
  .period-container-item .period-wrapper {
    margin-top: 35px;
  }
  .two-boxes .box-item > h3 {
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  .leaderboard-item {
    width: 100%;
  }
  .leaderbords {
    flex-direction: column;
  }
  .user-data-item-list .user-data-item {
    width: 100%;
  }
  .leaderboard-item.full {
    margin-top: 25px;
  }
}
@media (min-width: 992px) {
  .mobile-button {
    display: none;
  }
  .fs-menu,
  .mob {
    display: none;
  }
}
.loading-wrapper {
  padding: 50px;
  width: 100%;
}
.loading-wrapper h1 {
  color: #fff;
}
.profile-edit {
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background: rgba(98, 98, 98, 0.5);
  padding: 42px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin-top: 70px;
  width: 100%;
}
.profile-edit .right-side {
  width: 50%;
}
.profile-edit .right-side .input-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.profile-edit .right-side .input-item input:disabled {
  opacity: 0.7;
}
.profile-edit .right-side .input-item button {
  width: 340px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  color: #fff;
  background: #d0b478;
  border: none;
}
.profile-edit .right-side .input-item button:disabled {
  opacity: 0.6;
}
.profile-edit .right-side .input-item input {
  width: 340px;
  height: 50px;
  border-radius: 16px;
  border: 1px solid rgba(208, 180, 120, 0.5);
  background: #444343;
  color: #d0b478;
  font-size: 14px;
  font-family: "helv55";
  outline: none;
  padding-left: 15px;
}
.password-area {
  width: 50%;
}
.password-area input {
  width: 340px;
  height: 50px;
  border-radius: 16px;
  border: 1px solid rgba(208, 180, 120, 0.5);
  background: #444343;
  color: #d0b478;
  font-size: 14px;
  font-family: "helv55";
  outline: none;
  padding-left: 15px;
  margin-bottom: 15px;
}
.password-area input.is-invalid {
  border-color: red;
}
.password-area > button {
  width: 340px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  color: #fff;
  background: #d0b478;
  border: none;
}
.password-area > button:disabled {
  opacity: 0.6;
}
.auth-modal input {
  height: 55px !important;
}
.loading-item {
  margin: 40px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #ccc;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-data {
  margin: 40px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #ccc;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rules-container {
  background-image: url("../images/generalbg-min.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.rules-bottles {
  position: absolute;
  max-width: 60%;
  right: -16px;
  top: 5%;
}
@media (max-width: 991px) {
  .rules-bottles {
    display: none;
  }
  .main-area .main-area-container .main-area-in.rules {
    margin-top: 40px;
    max-width: 100%;
    padding: 0 15px;
  }
  .main-area .main-area-container .main-area-in.rules h2 {
    font-size: 20px;
  }
  .main-area .main-area-container .main-area-in.rules p {
    font-size: 14px;
  }
}
.custom-modal-item-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 80%);
  z-index: 999;
}
.custom-modal-item-wrapper .custom-modal-item-content {
  width: 800px;
  position: relative;
}
.custom-modal-item-wrapper .custom-modal-item-content .leaderboard-item {
  width: 100%;
  height: 90vh;
}
.custom-modal-item-wrapper .leaderboard-item .leaderboard-item-content {
  height: calc(90vh - 95px);
  margin-top: 80px;
}
.custom-modal-item-wrapper .leaderboard-item .leaderboard-header {
  height: 85px;
  overflow: unset;
  z-index: 4;
}
.custom-modal-item-wrapper .choose-box {
  padding: 4px;
  margin-right: 120px;
  z-index: 3;
}
.custom-modal-item-wrapper .choose-box .dt {
  height: 40px;
  padding: 0 18px;
  font-size: 14px;
}
.custom-modal-item-wrapper .choose-box .dr-item {
  width: 140px;
  margin-left: 25px;
}
.custom-modal-item-wrapper .close-btn {
  position: absolute;
  right: -25px;
  top: -15px;
  z-index: 5;
  cursor: pointer;
}
.custom-modal-item-wrapper .choose-box .dr-items {
  padding-top: 5px;
  left: -7px;
}
.main-area.general-notificaiton .main-area-in {
  margin-top: 90px;
}
@media (max-width: 991px) {
  .main-area.general-notificaiton .main-area-in {
    margin: 25px auto 0;
    width: calc(100% - 30px);
  }
  .custom-modal-item-wrapper .leaderboard-item .leaderboard-header h3 {
    display: none;
  }
  .custom-modal-item-wrapper .choose-box .dt {
    white-space: nowrap;
  }
  .custom-modal-item-wrapper .close-btn {
    right: 5px;
    top: -20px;
    z-index: 5;
  }
  .custom-modal-item-wrapper .close-btn svg {
    width: 40px;
  }
  .custom-modal-item-wrapper .custom-modal-item-content .leaderboard-item {
    width: calc(100% - 10px);
    margin-left: 5px;
  }
  .custom-modal-item-wrapper .custom-modal-item-content .leaderboard-item {
    width: calc(100% - 10px);
    height: 80vh;
  }
  .custom-modal-item-wrapper .leaderboard-item .leaderboard-item-content {
    height: calc(80vh - 95px);
    margin-top: 80px;
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .custom-modal-item-wrapper .user-data-item-list .user-data-item {
    width: calc(100% - 15px);
  }
  .user-profile-wrapper .notification {
    flex-direction: column;
  }
  .user-profile-wrapper .notification .left {
    flex-direction: column;
    align-items: center;
  }
  .user-profile-wrapper .notification svg {
    margin: 0;
    margin-bottom: 30px;
  }
  .user-profile-wrapper .notification p {
    text-align: center;
    line-height: 1.4;
  }
  .user-profile-wrapper .notification .close {
    margin-top: 20px;
  }
  .profile-edit {
    flex-direction: column;
    margin-top: 25px;
    padding: 15px;
    margin-bottom: 50px;
  }
  .password-area {
    margin-bottom: 50px;
    width: 100%;
    padding-right: 0 !important;
  }
  .password-area button {
    width: 100%;
  }
  .title-item-h3 {
    font-size: 15px !important;
    margin-bottom: 15px !important;
  }
  .password-area input {
    width: 100%;
  }
  .profile-edit .right-side {
    width: 100%;
  }
  .profile-edit .right-side .input-item input {
    width: 100%;
  }
  .profile-edit .right-side .input-item button {
    width: 100%;
  }
}

.question-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/bearmodalbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}
.question-modal .question-modal-content {
  background: rgba(66, 66, 66, 1);
  border-radius: 15px;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  display: flex;
  border-bottom: 5px solid #9e8b64;
  max-width: 600px;
  border-left: 2px solid rgba(158, 139, 100, 1);
  border-top: 2px solid rgba(158, 139, 100, 1);
  border-right: 2px solid rgba(158, 139, 100, 1);
}
.question-modal .question-modal-content svg {
  margin-top: -75px;
}
.question-modal .question-modal-content h4 {
  font-size: 20px;
  color: #fff;
  font-family: "helv65" !important;
  font-feature-settings: "case";
  max-width: 100%;
  text-align: center;
  line-height: 1.5;
  margin-top: 55px;
}
.question-modal .question-modal-content .buttons-wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
.question-modal .question-modal-content .buttons-wrapper .btn {
  width: 175px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.question-modal .question-modal-content .buttons-wrapper .btn:first-child {
  background: #e0c78c;
}
.question-modal .question-modal-content .buttons-wrapper .btn:last-child {
  border: 1px solid #e0c78c;
  color: #e0c78c;
  margin-left: 15px;
}
.question-modal .question-modal-content .buttons-wrapper .btn:hover {
  opacity: 0.6;
  transition: all 0.3s;
}
@media (max-width: 768px) {
  .question-modal .question-modal-content {
    width: 90%;
  }
  .question-modal .question-modal-content .buttons-wrapper .btn {
    width: 135px;
  }
  .question-modal .question-modal-content h4 {
    font-size: 18px;
  }
  .question-modal .question-modal-content svg {
    width: 200px;
  }
}
.main-area.general-notificaiton .main-area-in {
  margin-top: 90px;
}
@media (max-width: 991px) {
  .mechanic-steps {
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
  }
  .mechanic-steps h4 {
    max-width: 95%;
  }
  .mechanic-steps .steps-row {
    margin-top: 0;
    background-image: url("../images/collectmobile.svg");
    width: 100%;
  }
  .collect-points .points {
    width: 100%;
  }
  .how-does-it-work-container-item .how-does-it-work .points img {
    height: auto;
    width: 100%;
  }

  .user-profile-wrapper .my-data .headline {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .user-profile-wrapper .my-data .headline h4 {
    margin-left: 0 !important;
  }
  .user-profile-wrapper .my-data .headline div {
    margin-bottom: 0;
    margin-right: 15px;
  }
  .user-profile-wrapper .my-data .upload-area {
    margin-top: 15px;
  }
  .prizes-row .swiper-slide:nth-child(3) img {
    height: 90px !important;
  }
  .prizes-row .swiper-slide:nth-child(4) img {
    height: 90px !important;
  }
  .user-profile-wrapper .my-data .upload-area {
    padding: 25px 25px 20px 25px;
  }
  .user-profile-wrapper .my-data .receipt-list .list-item .list-item-info{ 
    font-size: 12px;
    white-space: nowrap;
  }
}
.title-item-h3 {
  font-size: 25px;
  color: #fff;
  font-family: "helv65";
  font-feature-settings: "case";
  margin-bottom: 25px;
}
.all-users-list .rdt_TableHeadRow .rdt_TableCol:first-child {
  min-width: 140px !important;
}
.all-users-list .rdt_TableBody .rdt_TableCell:first-child {
  min-width: 140px !important;
}
.thankyou-wrapper {
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: center;
  min-height: 50vh;
}
.thankyou-wrapper h3 {
  text-align: center;
  width: 80%;
  font-size: 24px;
  line-height: normal;
  color: #e9d391;
}
.thankyou-wrapper p {
  font-size: 14px;
}
.swiper-side .gift-item {
  width: 300px;
}
.swiper-side .gift-item img {
  max-width: 100%;
}
.swiper-side .gift-item:last-child img {
  max-width: 180px;
}
.header-row.footer-row .left .btn-item {
  color: #dfc68b;
  font-size: 14px;
  font-family: "helv65";
  cursor: pointer;
  height: 48px;
  border: 1px solid rgba(208, 180, 120, 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
}
.bottom-part {
  width: 100%;
}
@media (min-width: 992px) and (max-width: 1340px) {
  .collect-points .points img:first-child {
    height: auto;
    max-width: 90%;
  }
}
@media (min-width: 1600px) {
  .center-img::before {
    background-size: 97%;
  }
}

.mobile-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: 0;
}
.mobile-logo img {
  width: 80px;
}
.mobile-logo .logo-item {
  display: block !important;
}

@media (max-width: 991px) {
  .header-row .header .right .wellcome {
    display: none;
  }
  [data-user="false"] {
    margin-left: 0;
  }
  .hide-mob {
    display: none !important;
  }
  .center-img::before {
    display: none;
  }
  .center-img {
    flex-direction: column;
    display: flex;
    align-items: center;
    height: auto;
  }
  .center-img img {
    position: unset !important;
    transform: unset !important;
  }
  .center-img img:first-child {
    width: 230px;
  }
  .center-img img:last-child {
    width: 420px;
    max-width: unset;
    margin-top: -40px;
    margin-bottom: 18px;
  }
  .main-area .main-area-container .prizes-row .swiper-side {
    margin-top: -100px;
    max-width: 360px;
  }
  .main-area .main-area-container .prizes-row {
    position: unset;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-top: 50px;
  }
  .main-area
    .main-area-container
    .prizes-row
    .swiper-side
    .gift-item:first-child {
    order: 2;
  }
  .main-area
    .main-area-container
    .prizes-row
    .swiper-side
    .gift-item:last-child {
    order: 1;
  }
  .main-area
    .main-area-container
    .prizes-row
    .swiper-side
    .gift-item:first-child
    img {
    height: 70px;
  }
  .main-area .main-area-container .prizes-row .text-side {
    margin-top: 21px;
    max-width: 360px;
    padding: 0 15px;
  }
  .main-area .main-area-container .prizes-row .text-side p div div {
    color: #ff0000;
    font-style: italic;
    cursor: pointer;
    font-size: 15px;
    margin-top: 5px;
  }
  .main-area .main-area-container .prizes-row .text-side h5 {
    display: none;
  }
  .main-area .main-area-container .prizes-row .text-side p {
    font-size: 12px;
    margin-top: 5px;
  }
  .how-to-container .how-to .steps-row img:first-child {
    display: none;
  }
  .how-to-container .how-to .steps-row img:last-child {
    max-width: 100%;
    height: auto;
  }
  .how-to-container .how-to .steps-row {
    height: auto !important;
    align-items: center;
  }
  .how-to-container .how-to .heading h3 {
    max-width: 100%;
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
  }
  .header-row.footer-row .header .logo-item {
    margin-top: 0;
    margin-left: 15px;
  }
  .header-row.footer-row .header .logo-item img {
    max-width: 120px;
  }
  .header-row.footer-row .right {
    display: flex;
  }
  .how-to-container .how-to .steps-row img:last-child {
    margin-left: -40px;
  }
  .how-to-container .how-to {
    padding-top: 120px;
  }
  /* .main-area .main-area-container .main-area-in {
    background-image: url('../images/mobbgheader.png');
  } */
   .btn-item.show-mob {
    border: none !important;
    padding: 0 !important;
    padding-right: 0 !important;
   }
   .btn-item.show-mob svg {
    width: 25px;
   }
}
@media (min-width: 992px) {
  .mobile-logo {
    display: none;
  }
  .how-to-container .how-to .steps-row img:last-child {
    display: none;
  }
  .show-mob {
    display: none !important;
  }
}


.rule-boxes {
  max-width: 80%;
  margin: 0 auto;
}
.rule-boxes .rule-box-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.rule-boxes .rule-box-list .rule-box-item {
  width: calc(100% / 3 - 10px);
  border: 5px solid rgba(205, 183, 136, 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-bottom: 15px;
}
.rule-boxes .rule-box-list .rule-box-item svg {
  min-width: 100px;
  margin-right: 15px;
}
.rule-boxes .rule-box-list .rule-box-item p {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
}
.rule-boxes h2 {
  color: #fff;
  font-family: "helv65" !important;
  font-size: 36px;
  padding: 25px 30px;
  margin-bottom: 25px;
  font-feature-settings: "case";
  text-align: center;
}
@media (max-width: 991px) {
  .mechanic-steps h4 svg:last-child {
    display: block;
  }
  .mechanic-steps h4 svg:first-child {
    display: none;
  }
  .how-does-it-work-container-item .how-does-it-work .generals .bottom-row-in {
    flex-direction: column;
  }
  .how-does-it-work-container-item
    .how-does-it-work
    .generals
    .bottom-row-in
    img {
    order: 2;
    margin-top: 35px;
    max-width: 100%;
  }
  .how-does-it-work-container-item
    .how-does-it-work
    .generals
    .bottom-row-in
    .rg {
    order: 1;
    max-width: 100%;
    margin-top: 20px;
  }
  .additional-box h3 {
    font-size: 20px;
  }
  .additional-box {
    max-width: unset;
    width: calc(100% - 30px);
    border: 1px dashed #d0b478;
    padding-top: 25px;
  }
  .rule-boxes .rule-box-list {
    flex-direction: row;
    flex-wrap: unset;
    overflow-x: auto;
  }
  .rule-boxes .rule-box-list .rule-box-item {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    min-width: 325px;
    margin-right: 15px;
    flex-direction: column;
    align-items: flex-start;
  }
  .rule-boxes .rule-box-list .rule-box-item svg {
    height: 100px;
  }
  .rule-boxes .rule-box-list .rule-box-item p {
    font-size: 14px;
    margin-top: 15px;
  }
  .period-container-item .period-wrapper .play-period h3 {
    font-size: 16px;
  }
  .period-container-item .what-happens h3 {
    line-height: 26px;
    font-size: 18px;
  }
  .period-container-item .what-happens p {
    font-size: 14px;
  }
  .period-container-item {
    margin-bottom: 50px;
  }
  .how-does-it-work-container-item .how-does-it-work .generals h3 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .mechanic-steps h4 svg:last-child {
    display: none;
  }
}
@media (max-width: 1330px) {
  .rule-boxes {
    max-width: calc(100% - 30px);
  }
}
.mechanics-wrapper {
  position: relative;
}
.mechanics-wrapper::before {
  content: "";
  background-image: url("../images/mechbg.png");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 260px);
  background-size: cover;
  background-position: center;
}
.mechanics-wrapper > div {
  z-index: 2;
  position: relative;
}

@media (max-width: 991px) {
  .mechanics-wrapper::before {
    display: none;
  }
  .mechanic-steps h4 {
    max-width: unset;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 50px;
  }
  .how-does-it-work-container-item .how-does-it-work {
    padding: 75px 15px 0;
  }
  .additional-box .additional-box-content li:nth-child(3) {
    display: flex;
    align-items: center;
    flex-wrap: unset;
    flex-direction: column;
  }
  .additional-box .additional-box-content li:nth-child(3) span {
    padding-left: 20px;
    display: inline-block;
  }
  .additional-box .additional-box-content li:nth-child(3) p {
    padding-left: 10px;
    display: inline-block;
  }
  .additional-box .additional-box-content li:nth-child(3)::before {
    position: absolute;
    left: 0;
    top: 25px;
  }
  .rule-boxes h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .rule-boxes .rule-box-list .rule-box-item p {
    font-size: 14px;
    margin-top: 20px;
    height: 120px;
  }
  .period-container-item .period-wrapper .play-period {
    margin-bottom: 25px;
  }
  .period-container-item .what-happens {
    margin-bottom: 50px;
  }
  .mechanics-wrapper::before {
    display: block !important;
    content: "";
    background-image: url("../images/mechmobile.png");
    width: 100%;
    background-size: cover;
    background-position: top center;
    top: 0;
    height: calc(100% + 240px);
    background-repeat: no-repeat;
  }
}
@media (max-width: 1440px) {
  .choose-box .dr-item {
    width: 180px;
  }
}
@media (max-width: 1330px) {
  .winners-wrapper .winners-boxes {
    flex-direction: column;
  }
  .winners-wrapper .winners-boxes .rg {
    width: 100%;
    padding-left: 0;
  }
  .winners-wrapper .winners-boxes .lf {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .winners-wrapper .winners-boxes .lf {
    flex-wrap: wrap;
  }
  .winners-wrapper .winners-boxes .lf .choose-period {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .winners-wrapper .winners-boxes .rg {
    margin-top: 0;
  }
  .winners-wrapper .winners-boxes .lf .places .pl-item:first-child {
    margin-bottom: 0 !important;
  }
  .winners-wrapper .winners-boxes .lf .places .pl-item:last-child .user-info {
    padding-left: 25px;
  }
  .winners-wrapper .winners-boxes {
    margin-top: 0;
  }
  .main-area.home-main {
    background-image: url("../images/mobilemainbg-min.png") !important;
    background-position: center !important;
  }
}
.main-area.home-main {
  background-image: url("../images/mainbg-min.png");
  background-size: cover;
  background-position: center -170px;
  background-repeat: no-repeat;
}
@media (max-width: 415px) {
  .main-area.home-main {
    background-size: contain !important;
  }
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper svg {
  margin-top: 3px;
}
.react-datepicker-wrapper input {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding-left: 30px;
  font-size: 14px;
  height: 38px;
}
.react-datepicker-wrapper input:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border-color: #86b7fe;
  outline: 0;
}
.res-pass-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 15px;
}
.res-pass-row div {
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}
.res-pass-row div:hover {
  text-decoration: underline;
}
.res-pass-item input {
  width: 240px;
  height: 40px;
  padding-left: 10px;
  line-height: normal;
  border-radius: 8px;
}
.res-pass-item input {
  width: 240px;
  height: 40px;
  padding-left: 10px;
  line-height: normal;
  font-size: 14px;
  color: #000;
  border-radius: 8px;
  margin-bottom: 15px;
}
.res-pass-item button {
  width: 240px;
  height: 40px;
  padding-left: 10px;
  line-height: normal;
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
  background: #dfc68b !important;
  border-color: #dfc68b !important;
  box-shadow: none !important;
}