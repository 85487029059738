@font-face {
  font-family: "bpgcaps";
  src: url("../fonts/bpgcaps.ttf");
}
@font-face {
  font-family: "firago";
  src: url("../fonts/FiraGO.otf");
}
@font-face {
  font-family: "bpgnino";
  src: url("../fonts/bpgnino.ttf");
}
@font-face {
  font-family: "robotoReg";
  src: url("../fonts/RobotoRegular.ttf");
}
@font-face {
  font-family: "helv55";
  src: url("../fonts/helv55.otf");
}
@font-face {
  font-family: "helv65";
  src: url("../fonts/helv65.otf");
}
body,
html {
  background: #fff;
}
* {
  font-family: "helv55" !important;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  min-height: 768px;
  position: relative;
}
.dashboard-wrapper {
  width: 100%;
  flex: 1;
  background-color: #fff;
  z-index: 1;
  position: relative;
  min-height: 100vh;
}
.header-wrapper {
  height: 60px;
  width: 100%;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
}
.header-in {
  max-width: 80vw;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.header-in ul {
  display: flex;
  align-items: center;
  margin: 0;
  list-style: none;
}
.header-in ul li {
  margin-right: 25px;
  font-size: 16px;
  cursor: pointer;
  padding: 0 15px;
  position: relative;
  color: #818181;
}
.header-in ul li.active::before {
  content: "";
  height: 1px;
  background: #000;
  position: absolute;
  bottom: -18px;
  width: 100%;
  left: 0;
}
.header-in ul li.active {
  color: #000;
}
.header-in ul li a {
  text-decoration: none;
  color: #000;
}
.header-wrapper h2 {
  color: #000;
  font-family: "firago";
  margin: 0;
  font-size: 20px;
}
.header-wrapper button {
  border: none;
  background: #fff;
  padding: 8px 15px;
  border-radius: 8px;
  font-family: "firago";
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}
.header-wrapper button:hover {
  opacity: 0.8;
}
.signIn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.signIn-card {
  width: 400px;
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
  z-index: 1;
  position: relative;
}
.signIn-card h3 {
  text-align: center;
  font-size: 20px;
  font-family: "firago";
}
.signIn-form {
  width: 100%;
  margin-top: 35px;
}
.signIn-form .form-item {
  width: 100%;
  margin-bottom: 25px;
  display: flex;
}
.signIn-form .form-item input {
  height: 50px;
  width: 100%;
  font-family: "firago";
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 8px;
}
.signIn-form .form-item input:focus {
  border-color: #dfc68b !important;
  box-shadow: none;
  outline: none;
}
.signIn-form .form-item button {
  width: 100%;
  border: none;
  height: 50px;
  font-family: "firago";
  background: #dfc68b;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
}
.signIn-form .form-item button:hover {
  opacity: 0.8;
}
.error {
  margin-bottom: 15px;
  color: red;
  font-family: "firago";
}
.dashboard-content {
  max-width: 1400px;
  margin: 40px auto 0;
  padding: 0 15px;
}
.nav-item {
  padding: 0;
}
.nav-item button {
  font-family: "bpgcaps";
  font-weight: bold;
  padding: 25px 25px 20px;
  font-size: 16px;
}
.nav-item button[aria-selected="false"] {
  color: #d8ab00;
}
.tab-content {
  padding: 25px 0;
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 25px;
}
.tab-content table img {
  width: 50px;
  border-radius: 5px;
}
.tab-content table td {
  vertical-align: middle;
  font-family: "firago";
  font-size: 14px;
  border: 1px solid #eeeeee;
  padding: 15px;
}
.tab-content table td p * {
  font-size: 14px !important;
  text-align: left !important;
  color: #000 !important;
  font-family: "firago" !important;
  list-style-type: none !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
  font-weight: unset !important;
}

.tab-top {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}
.tab-top .add {
  background: #1e3f72;
  border: none;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-family: "firago";
  border-radius: 5px;
}
button:hover {
  opacity: 0.8;
}
.modal.show .custom-modal-content {
  opacity: 1;
  visibility: visible;
}
.custom-modal-footer {
  width: 100%;
}
.custom-modal-footer button {
  background: #1e3f72;
  font-family: "firago";
  font-size: 14px;
  padding: 10px 25px;
}
.image-preview {
  display: flex;
  align-items: center;
}
.dropzone[role="presentation"] {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.image-preview:hover {
  opacity: 0.8;
}
.image-preview p {
  margin: 0;
  font-family: "firago";
  font-size: 14px;
}
.image-preview input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.image-preview img {
  height: 100%;
}
.custom-modal-body {
  width: 100%;
}
.form-label {
  font-family: "firago";
  font-weight: bold;
  font-size: 14px;
}
.custom-modal-body input {
  font-family: "firago";
  font-size: 14px;
}
.thumbsContainer {
  margin-left: 15px;
}
.thumbsContainer img {
  height: 120px;
  width: 100%;
}
textarea {
  font-family: "firago" !important;
  font-size: 14px !important;
}
[data-lang="true"] * {
  font-family: "robotoReg" !important;
}
[data-lang="true"] .logo-wrapper p {
  font-size: 15px;
}
[data-lang="true"] .headline h1 {
  margin-top: 0;
}
.skeleton {
  background: linear-gradient(
    110deg,
    rgb(236 236 236 / 6%) 8%,
    rgb(245 245 245 / 7%) 18%,
    #2647742b 33%
  );
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  overflow: hidden;
  margin-bottom: 15px;
  height: 50px;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.list-row-item {
  display: flex;
  background: #f7f7f7;
  margin-bottom: 7px;
  padding: 15px;
  align-items: center;
  border-radius: 5px;
  border-bottom: 8px solid #fff;
}
.item-in {
  margin: 0 10px;
  min-width: 100px;
  width: 80px;
  flex: 1;
  text-align: left;
}

.item-in:first-child {
  max-width: 50px !important;
}
.item-in img {
  width: 30px;
  height: 30px;
}
.item-in h2 {
  font-size: 12px;
  margin-bottom: 0;
  word-break: break-all;
}
.card-block {
  border: 2px solid #f4f4f4;
  padding: 10px;
  border-radius: 10px;
  padding-bottom: 30px;
}
.receipt-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.item-in .receipt-tooltip {
  position: absolute;
  width: 200px !important;
  z-index: 4;
  top: 32px;
  background: #fff;
  min-height: 200px !important;
  height: auto !important;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  padding: 15px;
  display: none;
  right: -170px;
  flex-direction: column;
}
.item-in:hover .receipt-tooltip {
  display: flex;
}
.item-in .receipt-tooltip img {
  height: 210px !important;
  width: auto !important;
}
.item-in .receipt-tooltip a {
  font-size: 14px;
  text-align: left;
  width: 100%;
  display: inline-block;
  margin-top: 5px;
}
[role="grid"] {
  padding-right: 15px;
}
.header-bar {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
}
.header-bar input {
  font-size: 14px;
  width: 320px;
  height: 35px;
  padding-left: 8px;
}
.refresh-data {
  border: 0;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 5px;
  background: #00bd85;
  color: #fff;
  font-weight: bold;
}
.review-button {
  border: 0;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 5px;
  background: #fd7e14;
  color: #fff;
  font-weight: bold;
}
.card-block > .list-row-item {
  padding-right: 30px;
}
.no-data-title {
  text-align: center;
  margin: 50px 0;
  font-size: 15px;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 8px;
}
.user-row {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  background: #f6f6f6;
}
.user-row .user-info-item {
  margin-right: 15px;
  font-size: 14px;
}
.check-review-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 30px;
  border: 2px dashed #ccc;
  padding: 35px;
  border-radius: 15px;
}
.check-review-area .check-image {
  display: flex;
  flex-direction: column;
}
.check-review-area .check-image img {
  height: auto;
  background: #d1d1d1;
  padding: 10px;
  border-radius: 8px;
}
.check-review-area .check-image a {
  font-size: 14px;
  margin-top: 8px;
}
.check-review-area .check-input-data {
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
}
.check-review-area .check-input-data .input-item {
  margin: 0 15px;
}
.check-review-area .check-input-data .input-item input {
  height: 40px;
  font-size: 16px;
  padding-left: 5px;
}
.check-review-area .comment-area {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background: #f7f7f7;
  padding: 25px;
}
.check-review-area .comment-area textarea {
  width: 100%;
  padding: 5px;
  height: 100px;
}
.check-review-area .comment-area button {
  margin-bottom: 15px;
  border: none;
  font-size: 14px;
  background: black;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}
.reject-button {
  background: red !important;
  border-color: red !important;
  font-weight: bold !important;
  margin-right: 15px;
}
.hold-button {
  background: #fd7e14 !important;
  border-color: #fd7e14 !important;
  font-weight: bold !important;
}
.modal-footer {
  justify-content: space-between !important;
}
.modal-footer button {
  width: 120px;
}
.block-user-btn {
  font-size: 12px !important;
  background: red !important;
  border-color: red !important;
  min-width: 90px;
}
.hold-btn {
  font-size: 12px !important;
  white-space: nowrap;
  margin-left: 10px;
  background: orange !important;
  min-width: 90px;
}
.select-item {
  height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  border: 2px solid #ddd;
  border-radius: 4px;
}
.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.loading-wrapper h1 {
  font-size: 18px;
}
.dashboard-content select[aria-label],
.dashboard-content select[aria-label] + svg {
  display: none;
}
#pagination-first-page,
#pagination-last-page {
  display: none;
}

.rdt_TableHeadRow .rdt_TableCol:first-child {
  width: 100px;
  max-width: 100px;
  min-width: unset !important;
}
.rdt_TableRow .rdt_TableCell:first-child {
  width: 100px;
  max-width: 100px;
  min-width: unset !important;
}
.rdt_TableRow .rdt_TableCell:last-child,
.rdt_TableHeadRow .rdt_TableCol:last-child {
  min-width: 255px;
}
.rdt_TableHeadRow .rdt_TableCol:nth-child(6) {
  max-width: 100px;
}
.rdt_TableRow .rdt_TableCell:nth-child(6) {
  max-width: 100px;
}
.rdt_TableHeadRow .rdt_TableCol:nth-child(7) {
  min-width: 220px;
}
.rdt_TableRow .rdt_TableCell:nth-child(7) {
  min-width: 220px;
}
.rdt_TableHeadRow .rdt_TableCol:nth-child(5) {
  min-width: 120px;
}
.rdt_TableRow .rdt_TableCell:nth-child(5) {
  min-width: 120px;
}
.rdt_TableHeadRow .rdt_TableCol,
.rdt_TableRow .rdt_TableCell {
  min-width: 50px;
}
.filter-side {
  margin-bottom: 15px;
}
.filter-side input {
  height: 40px;
  width: 345px;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding-left: 8px;
}
.approve-btn {
  background: #00a558;
  min-width: 90px;
}
.rdt_TableRow .rdt_TableCell [data-tag="allowRowEvents"] {
  white-space: pre-wrap;
}
.rdt_TableCol {
  font-weight: bold;
}
.export-btn {
  margin: 0 15px 15px;
  background: var(--bs-green);
  color: #fff;
  border: none;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: bold;
}
.receipt-table .rdt_TableRow .rdt_TableCell:nth-child(5),
.receipt-table .rdt_TableHeadRow .rdt_TableCol:nth-child(5) {
  min-width: 250px;
  max-width: 250px;
}
.receipt-table .rdt_TableRow .rdt_TableCell:nth-child(6),
.receipt-table .rdt_TableHeadRow .rdt_TableCol:nth-child(6) {
  min-width: 100px;
  max-width: 100px;
}
.receipt-table .rdt_TableRow .rdt_TableCell:nth-child(7),
.receipt-table .rdt_TableHeadRow .rdt_TableCol:nth-child(7) {
  min-width: 150px;
  max-width: 150px;
}
.receipt-table
  .rdt_TableRow:nth-last-child(-n + 7)
  .rdt_TableCell
  .receipt-tooltip {
  top: -220px;
  right: -158px;
}
.receipt-table .rdt_TableRow .rdt_TableCell:nth-child(2),
.receipt-table .rdt_TableHeadRow .rdt_TableCol:nth-child(2) {
  min-width: 130px;
  max-width: 130px;
}
.receipt-table .rdt_TableRow .rdt_TableCell:nth-child(3),
.receipt-table .rdt_TableHeadRow .rdt_TableCol:nth-child(3) {
  min-width: 130px;
  max-width: 130px;
}
.receipt-table .rdt_TableRow .rdt_TableCell:nth-child(4),
.receipt-table .rdt_TableHeadRow .rdt_TableCol:nth-child(4) {
  min-width: 130px;
  max-width: 130px;
}
.receipt-table .rdt_TableRow .rdt_TableCell:last-child,
.receipt-table .rdt_TableHeadRow .rdt_TableCol:last-child {
  min-width: 230px;
  max-width: 230px;
}
.receipt-table .rdt_TableRow .rdt_TableCell:nth-last-child(2),
.receipt-table .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2) {
  min-width: 130px;
  max-width: 130px;
}
.receipt-table .rdt_TableRow .rdt_TableCell {
  padding-top: 8px;
  padding-bottom: 8px;
}
.receipt-table .rdt_TableRow [data-tag="allowRowEvents"] {
  display: flex;
}
.winners-table .rdt_TableHeadRow .rdt_TableCol,
.winners-table .rdt_TableRow .rdt_TableCell {
  min-width: 150px !important;
}
.receipt-table .rdt_TableRow:first-child .rdt_TableCell .receipt-tooltip {
  top: 0;
  right: -158px;
}
.receipt-table .rdt_TableRow:nth-child(2) .rdt_TableCell .receipt-tooltip {
  top: 0;
  right: -158px;
}
.receipt-table .item-in {
  margin: 0 !important;
}
.receipt-table .item-in img {
  width: 45px;
  height: 45px;
}
.rdt_TableHeadRow {
  background-color: rgb(238 238 238) !important;
}
.receipt-table .rdt_TableBody {
  min-height: 350px;
}